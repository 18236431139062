import { useTranslation } from "react-i18next";
import { AssessmentCheckboxRadio } from "ui-components";

interface ListingFilter {
  handleListingFilter: (value: string) => void;
  listingFilter: string;
}
const ListingFilter: React.FC<ListingFilter> = ({
  handleListingFilter,
  listingFilter,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="listingFilters">
        <div
          className="checkboxFilters"
          onClick={() => {
            handleListingFilter("All");
          }}
        >
          <AssessmentCheckboxRadio
            name="hasResponse"
            type="radio"
            checked={listingFilter == "All"}
          />
          <p className="listingRadioCheckLabel">{t("GENERAL.ALL")}</p>
        </div>
        <div
          className="checkboxFilters"
          onClick={() => {
            handleListingFilter("New");
          }}
        >
          <AssessmentCheckboxRadio
            name="hasResponse"
            type="radio"
            checked={listingFilter == "New"}
          />
          <p className="listingRadioCheckLabel">{t("GENERAL.NEW")}</p>
        </div>
        <div
          className="checkboxFilters"
          onClick={() => {
            handleListingFilter("Initiated");
          }}
        >
          <AssessmentCheckboxRadio
            name="hasResponse"
            type="radio"
            checked={listingFilter == "Initiated"}
          />
          <p className="listingRadioCheckLabel">{t("GENERAL.INITIATED")}</p>
        </div>
        <div
          className="checkboxFilters"
          onClick={() => {
            handleListingFilter("Submitted");
          }}
        >
          <AssessmentCheckboxRadio
            name="hasResponse"
            type="radio"
            checked={listingFilter == "Submitted"}
          />
          <p className="listingRadioCheckLabel">{t("GENERAL.SUBMITTED")}</p>
        </div>
        <div
          className="checkboxFilters"
          onClick={() => {
            handleListingFilter("Deferred");
          }}
        >
          <AssessmentCheckboxRadio
            name="hasResponse"
            type="radio"
            checked={listingFilter == "Deferred"}
          />
          <p className="listingRadioCheckLabel">{t("GENERAL.DEFERRED")}</p>
        </div>
        <div
          className="checkboxFilters"
          onClick={() => {
            handleListingFilter("Blocked");
          }}
        >
          <AssessmentCheckboxRadio
            name="hasResponse"
            type="radio"
            checked={listingFilter == "Blocked"}
          />
          <p className="listingRadioCheckLabel">{t("GENERAL.ABORTED")}</p>
        </div>
      </div>
    </div>
  );
};

export default ListingFilter;
