import { SetStateAction, useEffect, useRef, useState } from "react";
import "./Listing.scss";
import { Bullets, Pagination, TemplateSearch, showToast } from "ui-components";
import SubmitAssessmentListingIcon from "../../../../utils/Images/SubmitAssessmentListingIcon";
import { getApplicantAssessmentList } from "../../../../service/SubmitAssessment/SubmitAssessment";
import { useTranslation } from "react-i18next";
import DataNotAvailable from "../../../../utils/DataNotFound/DataNotAvailable";
import {
  convertDate,
  handleKeyPressSearch,
  manageDuplicateSkills,
} from "../../../../utils/Common/Constant";
import Footer from "../../../../components/Footer/Footer";
import ListingFilter from "./ListingFilter";
import useDebounce from "../../../../utils/Hooks/useDebounce";
import SubmitAssessmentHeader from "../../../../components/SubmitAssessment/Header/SubmitAssessmentHeader";
const Listing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [listing, setListing] = useState([]);
  const [listingFilter, setlistingFilter] = useState<string>("All");
  const [listingSearch, setListingSearch] = useState<string>("");
  const searchAssessment = useDebounce(listingSearch.trim(), 700);
  const listRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const applicantId = localStorage.getItem("applicantId") || "";
  const [pageSize, setPageSize] = useState(10);

  // Get current language
  const currentLanguage = i18n.language;

  const buttonStatus: any = {
    New: t("GENERAL.START"),
    Initiated: t("GENERAL.RESUME"),
    Submitted: t("GENERAL.SUBMITTED"),
    Deferred: t("GENERAL.HIDE"),
  };

  const handleListingFilter = (value: string) => {
    setlistingFilter(value);
    setCurrentPage(1);
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleAssessmentList();
        setlistingFilter("All");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleAssessmentList = async () => {
    const res = await getApplicantAssessmentList(
      applicantId,
      listingFilter,
      currentPage,
      pageSize,
      searchAssessment,
    );
    setListing(res?.data ?? []);
    setTotalCount(res?.totalCount);
  };

  useEffect(() => {
    handleAssessmentList();
  }, [listingFilter, currentPage, pageSize, searchAssessment]);

  useEffect(() => {
    // Scroll to the top of the list when the current page changes
    if (listRef.current) {
      listRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentPage]);

  function getResultStatus(applicationStatus: string) {
    switch (applicationStatus) {
      case "Blocked":
        return "Blocked";
      case "New":
      case "Initiated":
        return "---";
      case "Submitted":
        return "Awaited";
      case "Barred":
        return "Barred";
      case "Deferred":
        return "Deferred";
    }
  }
  return (
    <>
      <SubmitAssessmentHeader />
      <div
        ref={listRef}
        style={{
          overflowY: "auto",
          height: "100vh",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
        className="container-fluid assessmentListingHead .hide-scrollbar"
      >
        <div className="listingTitle">
          <h2>{t("SUBMIT_ASSESSMENT_MY_ASSESSMENT")}</h2>
        </div>
        <div className="listingSearch">
          <TemplateSearch
            onChange={(e: SetStateAction<string>) => {
              setListingSearch(e);
              setCurrentPage(1);
            }}
            placeholder={t("SUBMIT_ASSESSMENT.SEARCH_PLACEHOLDER")}
            value={listingSearch}
            handleKeyPress={handleKeyPressSearch}
            iconName="searchList"
          />
        </div>
        {/* filters start */}
        <div className="listingHead">
          <ListingFilter
            handleListingFilter={handleListingFilter}
            listingFilter={listingFilter}
          />
        </div>
        {/* filters end */}
        <div className="listingMain">
          {listing?.length === 0 ? (
            <DataNotAvailable />
          ) : (
            (listing ?? []).map((item: any, index) => (
              <div
                className="assessmentListing mb-4"
                key={item?.assessmentId + index}
              >
                <div className="listing">
                  <div className="section-1">
                    <div className="logo">
                      <SubmitAssessmentListingIcon />
                      <p>{item?.applicationStatus}</p>
                    </div>
                    <div className="content">
                      <div className="d-flex gap-2">
                        <span className="title">{item?.title}</span>
                      </div>
                      <div className="skill">
                        <div className="d-flex gap-2 allskill">
                          {(
                            manageDuplicateSkills(item?.assessmentSkills) || []
                          ).map((item: any, index: number) => {
                            return (
                              <Bullets
                                key={index}
                                skillName={item?.skillName}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="assesmentDetails mt-2">
                        <div className="assessment-company">
                          <p className="">
                            <b>{t("SUBMIT_ASSESSMENT_COMPANY_PROVIDER")}</b>
                          </p>
                          <span className="p-content">
                            {item?.companyName ?? "N/A"}
                          </span>
                        </div>

                        <div className="question">
                          <p className="">
                            <b>{t("GENERAL.QUESTIONS")}</b>
                          </p>
                          <p className="p-content">{item?.totalQuestions}</p>
                        </div>
                        <div className="time">
                          <p className="">
                            <b>{t("GENERAL.TIME")}</b>
                          </p>
                          <p className="p-content">{`${item?.totalTime} ${t("GENERAL.MINS")}`}</p>
                        </div>
                        <div className="result">
                          <p className="">
                            <b>{t("GENERAL.RESULT")}</b>
                          </p>
                          <p className="p-content">
                            {getResultStatus(item?.applicationStatus)}
                            {/* {item?.resultStatus ? item?.resultStatus : "---"} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="section-2">
                    <p>{convertDate(item?.createdAt, false, true)}</p>
                    {item?.applicationStatus !== "Deferred" &&
                      item?.applicationStatus !== "Blocked" &&
                      item?.applicationStatus !== "Barred" &&
                      item?.applicationStatus !== "Submitted" && (
                        <button
                          onClick={() => {
                            if (item?.applicationStatus == "Submitted") {
                              showToast(t("GENERAL.COMING_SOON"), "success");
                            } else {
                              window.open(
                                `/${currentLanguage}/assessmentdetails/${item?.assessmentId}?appId=${item?.applicantId}&loggedIn=true`,
                                "_blank",
                                "noopener",
                              );
                            }
                          }}
                          className="viewBtn"
                        >
                          {buttonStatus[item?.applicationStatus]} &nbsp;
                        </button>
                      )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount || 0}
              pageSize={pageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
              ofLabel={t("GENERAL.OF")}
              resultLabel={t("GENERAL.RESULTS")}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Listing;
